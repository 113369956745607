import styled from 'styled-components';

export const BenefitsStyles = styled.section`
  position: relative;
  
  padding-bottom: 56px;

  .container {
    opacity: 0;
    transition: 1.4s ease opacity;

    &.active {
      opacity: 1;
    }
  }
  
  &::after {
    content: '';
    position: absolute;
    top: 320px;
    right: -20px;
    
    width: 139px;
    height: 139px;
    background: #E2F3FF;
    border-radius: 100%;
    
    @media (min-width: 500px) {
      width: 222px;
      height: 222px;

      top: -30px;
      right: -100px;
    }

    @media (min-width: 900px) {
      width: 366px;
      height: 366px;
    }
  }
  
  @media (min-width: 651px) {
    padding-bottom: 160px;
  }

  h2 {
    margin-bottom: 24px;
    
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    text-align: center;

    @media (min-width: 651px) {
      margin-bottom: 56px;
      
      font-size: 44px;
      line-height: 66px;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    
    @media (min-width: 801px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    img {
      @media (max-width: 800px) {
        width: 100%;
      }
    }
    
    ul {
      display: flex;
      flex-direction: column;
      gap: 26px;
      
      li {
        display: grid;
        grid-template-columns: 24px 1fr;
        gap: 12px;
        
        .checkMark {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          
          width: 24px;
          height: 24px;
          
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            
            display: flex;
            justify-content: center;
            align-items: center;

            background: var(--orange, #FF6F00);
            opacity: 0.20000000298023224;
            width: 24px;
            height: 24px;
            border-radius: 100%;
          }
        }
      }
    }
  }
`;
