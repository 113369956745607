import React from 'react';
import { BottomBarStyles } from './BottomBarStyles';

function BottomBar() {
  return (
    <BottomBarStyles>
      <div className="container">
        <span>2023 LiiNKED. All right reserved.</span>

        <div>
          <a href="#" target="_blank">Privacy Policy</a>
          <a href="#" target="_blank">Terms of Service</a>
        </div>
      </div>
    </BottomBarStyles>
  );
}

export default BottomBar;
