import React from 'react';
import Logo from '../../assets/icons/Logo';
import InstagramIcon from '../../assets/icons/footer/InstagramIcon';
import FacebookIcon from '../../assets/icons/footer/FacebookIcon';
import WhatsAppIcon from '../../assets/icons/footer/WhatsAppIcon';
import { FooterStyles } from './FooterStyles';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <FooterStyles>
      <div className="container">
        <div className="logoWrapper">
          <Link to="/"><Logo /></Link>

          <p className="textBase16">Deploying technologies to help restaurants reduce costs and conduct marketing efficiently</p>
        </div>

        <div className="sitemapWrapper">
          <span className="title">Site map</span>

          <ul>
            <a href="#ecosystem">Ecosystem</a>
            <a href="#benefits">Benefits</a>
            <a href="#join">Join LiiNKED</a>
          </ul>
        </div>

        <div className="contactsWrapper">
          <span className="title">Contact us</span>

          <ul>
            <li>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a href="#ecosystem" target="_blank"><InstagramIcon /></a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a href="#benefits" target="_blank"><FacebookIcon /></a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <a href="#join" target="_blank"><WhatsAppIcon /></a>
            </li>

          </ul>
        </div>
      </div>
    </FooterStyles>
  );
}

export default Footer;
