import styled from 'styled-components';

export const InputFieldStyles = styled.div`
  label {
    display: flex;
    flex-direction: column;
    gap: 3px;
    color: var(--grey-1, #FEFEFE);
  }

  .inputWrap {
    display: flex;
    align-items: center;
    
    .inputContainer {
      position: relative;
      
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr;
      
      .showPasswordButton {
        position: absolute;
        background: none;
        right: 10px;
        top: 9px;
      }
    }

    input {
      font-size: 16px;
      color: #000;
      background-color: inherit;
      border: 0;
      border-bottom: 2px solid #8C8C8C;
      box-sizing: border-box;
      padding: 10px 8px;
      transition: 0.3s all;
      font-family: 'Inter', sans-serif;
      outline: none;
      border-radius: 0;
      //
      // &:hover {
      // }
      //
       &:focus {
         border-bottom: 2px solid #5463FF;
       }
      
      &.error {
        border-bottom: 2px solid #F5222D;
      }

      &::placeholder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.50);
      }
      
      // &.filled {
      // }
      
      &.disabled {
        border: 1px solid rgba(140, 140, 140, 0.35);
      }
    }
    
    .childrenContainer {
      margin-bottom: 24px;
    }
  }

  .formErrorContainer {
    padding-top: 2px;
    
    display: grid;
    grid-template-columns: 16px 1fr;
    gap: 4px;

    color: var(--grey-1, #F5222D);
  }

  .required {
    color: var(--grey-1, #FEFEFE);
  }
`;
