import styled from 'styled-components';
import bannerImg from '../../assets/images/banner-img.desktop.jpg';

export const BannerStyles = styled.section`
  padding: 138px 0;

  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 11.94%, rgba(255, 255, 255, 0.00) 64.14%), url(${bannerImg});
  background-size: cover, cover;
  background-repeat: no-repeat;
  background-position: -2.168px -71.382px, center center;
  
  .container {
    opacity: 0;
    transition: 1.4s ease opacity;
    
    &.active {
      opacity: 1;
    }
  }
  
  h1 {
    color: #000;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 0.56px;
    
    max-width: 360px;
    margin-bottom: 40px;

    @media (min-width: 800px) {
      line-height: 140%;
      letter-spacing: 1.2px;
      font-size: 60px;
      max-width: 560px;
    }
    
    @media (min-width: 1000px) {
      max-width: 760px;
    }
  }
  
  a {
    display: flex;
    padding: 12px 46px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    border-radius: 24px;
    background: var(--light-blue, #00B0F0);

    color: var(--grey-1, #FDFDFD);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    transition: 0.3s ease all;
    box-sizing: border-box;

    &:hover {
      background-color: var(--blue-hover, #0077C2);
      cursor: pointer;
    }

    &:active {
      background-color: var(--blue-active, #005BA3);
    }
    
    @media (min-width: 601px) {
      width: fit-content;
    }
  }
`;
