import styled from 'styled-components';

export const JoinStyles = styled.div`
  position: relative;
  padding: 44px 0;

  background: #F1F9FF;

  @media (min-width: 601px) {
    padding: 80px 0;
  }

  .container {
    opacity: 0;
    transition: 1.4s ease opacity;

    &.active {
      opacity: 1;
    }
  }
  
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    border-radius: 8px;
    background: #FFF;

    /* Elevation | 1/200 */
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
    padding: 40px;
    box-sizing: border-box;
    text-align: center;

    span:nth-child(1) {
      margin-bottom: 16px;
      
      color: #000;
      font-size: 32px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.88px;
      
      @media (min-width: 601px) {
        font-size: 44px;
      }
    }
    
    span:nth-child(2) {
      margin-bottom: 32px;
    }
    
    button {
      display: flex;
      padding: 12px 46px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;

      border-radius: 24px;
      background: var(--light-blue, #00B0F0);

      color: var(--grey-1, #FDFDFD);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      transition: 0.3s ease all;
      box-sizing: border-box;

      &:hover {
        background-color: var(--blue-hover, #0077C2);
        cursor: pointer;
      }

      &:active {
        background-color: var(--blue-active, #005BA3);
      }

      @media (min-width: 601px) {
        width: fit-content;
      }
    }
  }
  
  .ballLeft, .ballRight {
    position: absolute;
    width: 152.604px;
    height: 152.604px;
    
    @media (min-width: 601px) {
      width: 221px;
      height: 221px;
    }

    @media (min-width: 901px) {
      width: 333px;
      height: 333px;
    }
  }

  .ballLeft {
    bottom: 0;
    left: 0;
  }

  .ballRight {
    top: 0;
    right: 0;
  }
  
  .content {
    max-width: 350px;
    margin: auto;
  }
  
  .form {
    margin-bottom: 40px;
    
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  h2 {
    margin-bottom: 24px;
    
    color: #000;
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    text-align: center;

    letter-spacing: 0.88px;
    
    @media (min-width: 601px) {
      margin-bottom: 32px;
      
      line-height: 66px;
      font-size: 44px;
    }
  }
  
  .button {
    display: flex;
    padding: 12px 46px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    border-radius: 24px;
    background: var(--light-blue, #00B0F0);

    color: var(--grey-1, #FDFDFD);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    transition: 0.3s ease all;
    box-sizing: border-box;

    &:hover {
      background-color: var(--blue-hover, #0077C2);
      cursor: pointer;
    }

    &:active {
      background-color: var(--blue-active, #005BA3);
    }

    @media (min-width: 601px) {
      width: 100%;
    }
  }
`;
