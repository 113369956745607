import React from 'react';

function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#F5222D"
        d="M.667 14h14.666L8 1.333.667 14zm8-2H7.332v-1.333h1.333V12zm0-2.667H7.332V6.667h1.333v2.666z"
      />
    </svg>
  );
}

export default ErrorIcon;
