import React, { useState } from 'react';
import { JoinStyles } from './JoinStyles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InputField from '../formComponents/input/InputField';
import BallLeft from '../../assets/icons/join/BallLeft';
import BallRight from '../../assets/icons/join/BallRight';
import { send } from 'emailjs-com';
import { InView } from 'react-intersection-observer';
import Modal from '../modal/Modal';

function Join() {
  const [afterSaveModalOpen, setAfterSaveModalOpen] = useState<boolean>(false);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      description: '',
    },
    onSubmit: (values) => {
      send(
        'service_w3pv3ua',
        'template_1emk46q',
        values,
        'NdAHf5j3Y8ymtkFcc',
      )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
        })
        .catch((err) => {
          console.log('FAILED...', err);
        });

      setAfterSaveModalOpen(true);
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Enter your name'),
      email: Yup.string()
        .email('Invalid email format')
        .required('Enter your email'),
    }),
  });

  return (
    <JoinStyles id="join">
      <BallLeft />
      <BallRight />

      {afterSaveModalOpen && (
        <Modal onClose={() => setAfterSaveModalOpen(false)}>
          <span>Thank you for contacting us!</span>
          <span className="textBase18">We will contact you as soon as possible</span>

          <button onClick={() => setAfterSaveModalOpen(false)} type="button">Back to the main page</button>
        </Modal>
      )}

      <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="container">
        <div className="content">
          <h2>Join LiiNKED</h2>

          <div className="form">
            <InputField
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Enter your name"
              error={touched && touched.name && errors && errors.name ? errors.name : ''}
            />

            <InputField
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Enter your email"
              error={touched && touched.email && errors && errors.email ? errors.email : ''}
            />

            <InputField
              name="description"
              value={values.description}
              onChange={handleChange}
              placeholder="Tell us about your restaurant(s)"
            />
          </div>

          <button type="button" onClick={() => handleSubmit()} className="button">Send</button>
        </div>
      </InView>
    </JoinStyles>
  );
}

export default Join;
