import styled from 'styled-components';

export const FooterStyles = styled.footer`
  padding: 56px 0 32px;
  border-bottom: 1px solid #D8E0EA;
  
  @media (min-width: 601px) {
    padding: 80px 0 40px;
  }
  
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    @media (min-width: 601px) {
      display: flex;
      justify-content: space-between;
    }

    .logoWrapper, .contactsWrapper, .sitemapWrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .contactsWrapper, .sitemapWrapper {
      
      .title {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
      }
    }
    
    .sitemapWrapper {
      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        
        a {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          width: fit-content;

          position: relative;

          &::after {
            position: absolute;
            content: '';
            width: 0;
            bottom: 0;
            left: 0;
            height: 1px;
            background-color: #000;
            transition: 0.3s ease all;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
    
    .logoWrapper {
      grid-column: 1/3;
      flex-basis: 254px;
      
      margin-bottom: 24px;
      
      @media (min-width: 601px) {
        margin-bottom: 0;
      }
    }
    
    .contactsWrapper {
      ul {
        display: flex;
        gap: 24px;
        align-items: center;
        
        li {
          svg {
            transition: 0.3s ease all;
            
            path {
              transition: 0.3s ease all;
            }
          }
        }
        
        li:nth-child(1) {
          svg {

            path {
              fill: #FFF;
            }
          }
        }
        
        li:nth-child(1):hover {
          svg {
            path {
              fill: #833AB4;
            }

            path:nth-child(1) {
              stroke: #833AB4 ;
            }
            
            path:nth-child(2) {
              stroke: #FFF;
            }
          }
        }

        li:nth-child(2) {
          svg {
            path {
              fill: #000;
            }
          }
        }

        li:nth-child(2):hover {
          svg {
            path {
              fill: #1877F2;
            }
          }
        }

        li:nth-child(3) {
          svg {
            path {
              fill: #000;
            }
          }
        }

        li:nth-child(3):hover {
          svg {
            path {
              fill: #25D366;
            }
          }
        }
      }
    }
  }
`;
