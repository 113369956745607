import React from 'react';

function Ecosystem1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      fill="none"
      viewBox="0 0 41 41"
    >
      <path
        fill="#FF6F00"
        d="M40.474 18.35l-4.22-1.47a15.488 15.488 0 00-2.11-5.115L36.062 7.8c.128-.256.064-.512-.128-.704l-2.046-2.174c-.192-.192-.512-.255-.704-.128L29.22 6.714a15.488 15.488 0 00-5.115-2.11l-1.47-4.22C22.442.192 22.25 0 21.93 0h-3.005a.614.614 0 00-.575.448l-1.471 4.22a15.488 15.488 0 00-5.115 2.11L7.8 4.859c-.256-.128-.512-.064-.704.128L4.923 7.033c-.192.192-.255.512-.128.704L6.714 11.7a15.487 15.487 0 00-2.11 5.115l-4.22 1.47c-.192.192-.384.384-.384.704v3.005c0 .256.192.512.448.575l4.22 1.471a15.487 15.487 0 002.11 5.115l-1.919 3.965c-.128.255-.064.511.128.703l2.11 2.11c.192.192.512.256.704.128l3.964-1.918a15.488 15.488 0 005.115 2.11l1.47 4.22c.064.255.32.447.576.447h3.005a.614.614 0 00.576-.447l1.47-4.22a15.488 15.488 0 005.115-2.11l3.965 1.918c.255.128.511.064.703-.128l2.11-2.11c.192-.192.256-.512.128-.703l-1.918-3.965a15.489 15.489 0 002.11-5.115l4.22-1.47a.614.614 0 00.447-.576V18.99c.064-.32-.128-.512-.383-.64zm-.832 3.134l-4.156 1.406a.731.731 0 00-.447.512c-.384 1.918-1.087 3.708-2.174 5.37-.128.192-.128.448-.064.64l1.918 3.9-1.47 1.47-3.9-1.917c-.193-.128-.448-.064-.64.064-1.663 1.087-3.453 1.854-5.371 2.174a.855.855 0 00-.512.447l-1.342 4.092h-2.047l-1.406-4.156a.73.73 0 00-.512-.447c-1.918-.384-3.708-1.087-5.37-2.174-.192-.128-.448-.128-.64-.064l-3.9 1.918-1.47-1.47 1.917-3.9c.128-.193.064-.448-.064-.64-1.087-1.663-1.854-3.453-2.174-5.371-.063-.128-.191-.32-.383-.384l-4.156-1.47v-2.047l4.156-1.406a.73.73 0 00.447-.512c.384-1.918 1.087-3.708 2.174-5.37.128-.192.128-.448.064-.64l-1.918-3.9 1.47-1.47 3.901 1.917c.192.128.448.064.64-.064 1.662-1.087 3.452-1.854 5.37-2.174a.855.855 0 00.512-.447l1.343-4.092h2.046l1.406 4.156a.731.731 0 00.512.447c1.918.384 3.708 1.087 5.37 2.174.192.128.448.128.64.064l3.9-1.918 1.47 1.47-1.917 3.901c-.128.192-.064.448.064.64 1.087 1.662 1.854 3.452 2.174 5.37a.855.855 0 00.447.512l4.156 1.406v1.983h-.064z"
      />
      <path
        fill="#FF6F00"
        d="M20.46 10.166A10.287 10.287 0 0010.166 20.46c0 5.691 4.604 10.295 10.294 10.295 5.691 0 10.295-4.604 10.295-10.294 0-5.691-4.604-10.295-10.294-10.295zm0 19.31a9.005 9.005 0 01-9.015-9.015 9.005 9.005 0 019.015-9.016 9.005 9.005 0 019.016 9.015 9.005 9.005 0 01-9.015 9.016z"
      />
      <path
        fill="#FF6F00"
        d="M21.483 15.665c-.32-.064-.703.128-.767.512l-1.854 8.376c-.064.32.128.703.512.767.32.064.703-.128.767-.512l1.854-8.376c.064-.383-.128-.703-.512-.767zm1.599 2.75c-.192.32-.064.703.256.895l2.046 1.15-2.046 1.151c-.32.192-.384.576-.256.896.192.32.575.383.895.255l3.005-1.726a.672.672 0 00.32-.576.671.671 0 00-.32-.575l-3.005-1.662c-.32-.192-.703-.064-.895.192zm-5.243 0c-.192-.32-.575-.448-.895-.256l-3.005 1.662a.672.672 0 00-.32.576c0 .255.128.447.32.575l3.005 1.726c.32.192.703.064.895-.255.192-.32.064-.704-.256-.896l-2.046-1.087 2.046-1.15c.32-.192.448-.576.256-.895z"
      />
    </svg>
  );
}

export default Ecosystem1;
