import React from 'react';
import { Link } from 'react-router-dom';
import { BenefitsStyles } from './BenefitsStyles';
import CheckMark from '../../assets/icons/benefits/CheckMark';

import img from '../../assets/images/benefits.jpg';
import { InView } from 'react-intersection-observer';

const listData = [
  'Produce cutting-edge technologies for your business by spreading development costs among LiinkNet members',
  'Get favorable prices for supplies and services with the aggregated purchasing power of LiinkNet members',
  'Reduce customer acquisition cost with network and community marketing.',
];

function Benefits() {
  return (
    <BenefitsStyles id="benefits">
      <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="container">
        <h2>Improving of LiiNKED</h2>

        <div className="content">
          <ul>
            {listData.map((text) => (
              <li key={text}>
                <div className="checkMark"><CheckMark /></div>
                <span className="textBase18">{text}</span>
              </li>
            ))}
          </ul>

          <img src={img} alt="Improving of LiiNKED" />
        </div>
      </InView>
    </BenefitsStyles>
  );
}

export default Benefits;
