import styled from 'styled-components';

export const BottomBarStyles = styled.div`
  padding: 32px 0 56px;
  
  @media (min-width: 601px) {
    padding: 40px 0 80px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    @media (min-width: 601px) {
      flex-direction: row;
      justify-content: space-between;
    }
    
    span {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
    }
    
    div {
      display: flex;
      gap: 24px;
    }
    
    a {
      color: var(--black, #000);
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;

      position: relative;
      
      &::after {
        position: absolute;
        content: '';
        width: 0;
        bottom: 0;
        left: 0;
        height: 1px;
        background-color: #000;
        transition: 0.3s ease all;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }
`;
