import React from 'react';

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_3_1343)">
        <path
          fill="#000"
          d="M13.859 24V13.053h3.673l.55-4.267H13.86V6.062c0-1.235.341-2.077 2.114-2.077l2.258-.001V.167c-.39-.05-1.73-.167-3.29-.167-3.258 0-5.488 1.988-5.488 5.64v3.146H5.769v4.267h3.684V24h4.406z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1343">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FacebookIcon;
