import React from 'react';

function Ecosystem4() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g clipPath="url(#clip0_3_1187)">
        <path
          fill="#FF6F00"
          d="M36.875 4.375h-6.306a3.746 3.746 0 00-7.388 0h-8.237a3.746 3.746 0 00-7.388 0H4.375A1.875 1.875 0 002.5 6.25v23.125H.625A.625.625 0 000 30v1.468a2.902 2.902 0 002.5 2.866V37.5a.625.625 0 00.625.625h35a.625.625 0 00.625-.625V6.25a1.875 1.875 0 00-1.875-1.875zM37.5 6.25v4.375h-3.75V6.25a1.855 1.855 0 00-.115-.625h3.24a.625.625 0 01.625.625zM26.875 2.5a2.5 2.5 0 012.411 1.875h-4.822A2.5 2.5 0 0126.875 2.5zm-15.625 0a2.5 2.5 0 012.411 1.875H8.84A2.5 2.5 0 0111.25 2.5zm-7.5 3.75a.625.625 0 01.625-.625h3.181A3.754 3.754 0 0011.25 8.75a.625.625 0 100-1.25 2.5 2.5 0 01-2.411-1.875H23.18a3.754 3.754 0 003.694 3.125.625.625 0 100-1.25 2.5 2.5 0 01-2.411-1.875h7.411a.625.625 0 01.625.625v4.375H3.75V6.25zm0 5.625H32.5V31.25a1.875 1.875 0 11-3.75 0V30a.625.625 0 00-.625-.625H23.75v-3.75a.621.621 0 00-.017-.084c1.878-.479 3.142-3.114 3.142-5.509 0-2.595-1.543-4.407-3.75-4.407a3.54 3.54 0 00-1.634.398 4.26 4.26 0 00-6.732 0 3.54 3.54 0 00-1.634-.398c-2.208 0-3.75 1.813-3.75 4.407 0 2.387 1.256 5.013 3.125 5.504v3.839H3.75v-17.5zm15 17.5V25.55a3.763 3.763 0 002.01-1.271c.432.6 1.05 1.043 1.758 1.258a.53.53 0 00-.018.088v3.75h-3.75zm-5 0v-3.843a3.388 3.388 0 001.74-1.253 3.763 3.763 0 002.01 1.271v3.825h-3.75zm9.375-5a2.144 2.144 0 01-1.612-1.182c.64-1.19.98-2.519.987-3.87.006-.753-.13-1.5-.402-2.203l.005-.012a2.307 2.307 0 011.022-.233c1.519 0 2.5 1.24 2.5 3.157 0 2.192-1.238 4.343-2.5 4.343zm-1.875-5.052c0 2.552-1.548 5.052-3.125 5.052S15 21.875 15 19.323c0-2.247 1.226-3.698 3.125-3.698s3.125 1.451 3.125 3.698zm-6.513 3.87a2.145 2.145 0 01-1.612 1.182c-1.262 0-2.5-2.15-2.5-4.343 0-1.918.981-3.157 2.5-3.157.354-.002.704.078 1.022.233l.006.009a5.967 5.967 0 00-.403 2.206 8.254 8.254 0 00.987 3.87zM1.25 31.467v-.842H27.5v.625a3.108 3.108 0 00.625 1.875H2.907a1.66 1.66 0 01-1.657-1.657zm2.5 2.908h26.875a3.093 3.093 0 001.875-.641v3.141H3.75v-2.5zm33.75 2.5h-3.75v-25h3.75v25z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1187">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Ecosystem4;
