import styled from 'styled-components';

export const ImprovingStyles = styled.section`
  position: relative;
  
  padding-bottom: 56px;

  .container {
    opacity: 0;
    transition: 1.4s ease opacity;

    &.active {
      opacity: 1;
    }
  }
  
  @media (min-width: 651px) {
    padding-bottom: 160px;
  }

  h2 {
    margin-bottom: 24px;
    
    color: #000;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    text-align: center;

    @media (min-width: 651px) {
      margin-bottom: 56px;
      
      font-size: 44px;
      line-height: 66px;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    
    @media (min-width: 801px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    
    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    
    img {
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
`;
