import React from 'react';

function Ecosystem2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
        fill="#FF6F00"
        d="M1.54 20.787h37.675a.609.609 0 00.608-.611.609.609 0 00-.608-.612h-1.823c0-9.234-7.306-16.779-16.407-17.103V1.223h1.216a.609.609 0 00.607-.612.609.609 0 00-.607-.611h-3.647a.609.609 0 00-.607.611c0 .339.271.612.607.612h1.216V2.46c-9.101.324-16.408 7.869-16.408 17.103H1.54a.609.609 0 00-.607.612c0 .338.271.611.607.611zM20.377 3.668c8.712 0 15.8 7.13 15.8 15.896h-31.6c0-8.765 7.087-15.896 15.8-15.896zM35.598 25.159l-7.648 7.79h-8.18a1.22 1.22 0 01-1.216-1.223 1.22 1.22 0 011.216-1.223h6.076a2.44 2.44 0 002.431-2.445 2.44 2.44 0 00-2.43-2.446h-9.932a3.01 3.01 0 00-2.148.896l-1.54 1.55H9.438v-1.834a.609.609 0 00-.607-.612H1.539a.609.609 0 00-.607.612V38.45c0 .339.271.612.607.612h7.293a.609.609 0 00.607-.612v-.611H28.7c.805 0 1.592-.33 2.16-.907l8.252-8.392c.469-.472.729-1.126.712-1.794a2.432 2.432 0 00-.806-1.755c-.96-.87-2.46-.798-3.42.167zM8.224 37.84H2.147V26.835h6.077V37.84zm30.025-10.162l-8.253 8.394a1.831 1.831 0 01-1.297.545H9.44v-7.336h3.04a.603.603 0 00.429-.18l1.719-1.729a1.806 1.806 0 011.289-.537h9.931c.67 0 1.215.548 1.215 1.223a1.22 1.22 0 01-1.215 1.223H19.77a2.44 2.44 0 00-2.43 2.445 2.44 2.44 0 002.43 2.446h8.434a.608.608 0 00.433-.182l7.824-7.969c.49-.493 1.272-.547 1.744-.12.251.229.395.54.403.879.009.338-.118.656-.358.899z"
      />
    </svg>
  );
}

export default Ecosystem2;
