import React, { useState } from 'react';
import { HeaderStyles } from './HeaderStyles';
import Logo from '../../assets/icons/Logo';
import { Link } from 'react-router-dom';

function Header() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  return (
    <HeaderStyles>
      <div className="container">
        <div className="content">
          <Link to="/" className={menuOpen ? 'active logo' : 'logo'}>
            <Logo />
          </Link>

          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button type="button" className={`burger-menu ${menuOpen ? 'active' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
            <div className="burger-line line1" />
            <div className="burger-line line2" />
            <div className="burger-line line3" />
          </button>

          <nav className={`navMobile ${menuOpen ? 'active' : ''}`}>
            <li>
              <a onClick={() => setMenuOpen(false)} tabIndex={menuOpen ? 0 : -1} href="#ecosystem">Ecosystem</a>
            </li>
            <li>
              <a onClick={() => setMenuOpen(false)} tabIndex={menuOpen ? 0 : -1} href="#benefits">Benefits</a>
            </li>
            <li>
              <a onClick={() => setMenuOpen(false)} tabIndex={menuOpen ? 0 : -1} href="#join">Join LiiNKED</a>
            </li>
          </nav>

          <nav className="navDesktop">
            <li>
              <a href="#ecosystem">Ecosystem</a>
            </li>
            <li>
              <a href="#benefits">Benefits</a>
            </li>
            <li>
              <a href="#join">Join LiiNKED</a>
            </li>
          </nav>
        </div>
      </div>
    </HeaderStyles>
  );
}

export default Header;
