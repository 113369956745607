import styled from 'styled-components';

export const EcosystemStyles = styled.section`
  padding: 56px 0;
  
  @media (min-width: 651px) {
    padding: 160px 0;
  }

  .container {
    opacity: 0;
    transition: 1.4s ease opacity;

    &.active {
      opacity: 1;
    }
  }
  
  h2 {
    margin-bottom: 56px;
    
    text-align: center;
    color: #000;
    letter-spacing: 0.88px;
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    
    @media (min-width: 651px) {
      font-size: 44px;
      font-weight: 600;
      line-height: 66px;
    }
  }
  
  ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;

    @media (min-width: 551px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 951px) {
      grid-template-columns: repeat(4, 1fr);
    }
    
    li {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 32px;
      flex: 1;
      
      h3 {
        margin-bottom: 12px;

        @media (min-width: 651px) {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
        }
      }
      
      p {
        padding: 0 10px;
      }
      
      .iconContainer {
        border-radius: 4px;
        background: #FFF;
        
        width: fit-content;
        margin: 0 auto;
        padding: 12px;

        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
`;
