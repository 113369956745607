import React from 'react';

function BallLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="302"
      height="283"
      fill="none"
      viewBox="0 0 302 283"
      className="ballLeft"
    >
      <circle
        cx="79.646"
        cy="221.646"
        r="219.5"
        stroke="url(#paint0_linear_3_1312)"
        strokeWidth="4"
        opacity="0.2"
        transform="rotate(39.203 79.646 221.646)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_1312"
          x1="303.86"
          x2="-160.231"
          y1="170.849"
          y2="146.118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A237E" />
          <stop offset="1" stopColor="#1A237E" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BallLeft;
