import styled from 'styled-components';

export const HeaderStyles = styled.header`
  padding: 17px 0;
  position: relative;
  
  height: 82px;
  box-sizing: border-box;
  
  @media (min-width: 651px) {
    padding: 17px 0;
  }

  .logo {
    &.active {
      position: fixed;
      z-index: 4;
      top: 17px;
      left: 16px;
    }
  }
  
  .content {
    max-width: 1000px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .navDesktop {
      display: none;
      
      @media (min-width: 651px) {
        display: flex;
      }
    }

    .burger-menu {
      width: 30px;
      height: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      z-index: 1;
    }

    .burger-line {
      width: 100%;
      height: 4px;
      background-color: #000;
      transition: transform 0.2s;
    }

    .burger-menu.active {
      .line1 {
        transform: translateY(8px) rotate(45deg);
      }
      
      .line2 {
        opacity: 0;
      }
      
      .line3 {
        transform: translateY(-8px) rotate(-45deg);

      }
    }
    
    .burger-menu {
      @media (min-width: 651px) {
        display: none;
      }
      
      &.active {
        position: fixed;
        z-index: 4;
        
        right: 16px;
        top: 30px;
      }
    }

    .navMobile {
      @media (min-width: 651px) {
        display: none;
      }
      
      position: fixed;
      z-index: 3;
      width: 100vw;
      min-height: 100vh;
      background: #FFF;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      transition: 0.3s ease transform;
      transform: translateX(100%);
      
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-top: 100px;
      
      &.active {
        transform: translateX(0);
      }
    }
    
    nav {
      gap: 80px;

      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      
      a {
        color: #000;
        position: relative;
        
        &::after {
          position: absolute;
          content: '';
          width: 0;
          bottom: 0;
          left: 0;
          height: 1px;
          background-color: #000;
          transition: 0.3s ease all;
        }
        
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
`;
