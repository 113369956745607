import React from 'react';

function WhatsAppIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_3_1352)">
        <path
          fill="#000"
          fillRule="evenodd"
          d="M19.15 3.27A11.077 11.077 0 0011.264 0C5.118 0 .116 5.001.114 11.149c0 1.965.512 3.883 1.488 5.574L.021 22.5l5.91-1.55a11.137 11.137 0 005.328 1.357h.005c6.144 0 11.146-5.002 11.149-11.15A11.08 11.08 0 0019.15 3.27zm-7.886 17.154h-.004a9.256 9.256 0 01-4.717-1.292l-.338-.201-3.508.92.937-3.42-.22-.35a9.244 9.244 0 01-1.418-4.932c.002-5.11 4.16-9.266 9.271-9.266 2.475 0 4.802.966 6.552 2.718a9.212 9.212 0 012.711 6.556c-.002 5.11-4.159 9.267-9.267 9.267zm5.083-6.94c-.279-.14-1.649-.814-1.904-.907-.255-.093-.441-.14-.627.14-.186.278-.72.906-.882 1.091-.162.187-.325.21-.603.07-.28-.14-1.177-.433-2.24-1.383-.829-.738-1.388-1.65-1.55-1.93-.163-.278-.002-.415.122-.568.301-.374.603-.767.696-.953.093-.186.046-.348-.023-.488-.07-.14-.627-1.51-.86-2.068-.225-.543-.455-.47-.626-.479A11.11 11.11 0 007.316 6c-.186 0-.487.07-.743.35-.255.278-.975.952-.975 2.323 0 1.372.998 2.696 1.138 2.882.139.186 1.964 3 4.759 4.207.664.287 1.183.458 1.588.587.667.212 1.274.182 1.755.11.535-.08 1.648-.674 1.88-1.325.232-.65.232-1.208.162-1.324-.07-.117-.255-.186-.533-.326z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_1352">
          <path fill="#fff" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default WhatsAppIcon;
