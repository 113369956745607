import React from 'react';
import { Link } from 'react-router-dom';
import { ImprovingStyles } from './ImprovingStyles';
import CheckMark from '../../assets/icons/benefits/CheckMark';
import img from '../../assets/images/improving/staff.jpg';

import { InView } from 'react-intersection-observer';

function Improving() {
  return (
    <ImprovingStyles>
      <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="container">
        <h2>Improving Working Condition & Benefits for Staff</h2>

        <div className="content">
          <div className="textBase18 textContainer">
            <span>We develop technologies and procedures to help kitchen staff optimize the food preparation process.</span>
            <span>LiiNKED restaurant partners are committed to giving 5% of their earnings through our channels to food makers.</span>
          </div>

          <img src={img} alt="Improving Working Condition & Benefits for Staff" />
        </div>
      </InView>
    </ImprovingStyles>
  );
}

export default Improving;
