import React from 'react';

function CheckMark() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        stroke="#FF6F00"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 3L4.5 8.5 2 6"
      />
    </svg>
  );
}

export default CheckMark;
