import React, { JSX } from 'react';
import { EcosystemStyles } from './EcosystemStyles';
import Ecosystem1 from '../../assets/icons/ecosystem/Ecosystem1';
import Ecosystem2 from '../../assets/icons/ecosystem/Ecosystem2';
import Ecosystem3 from '../../assets/icons/ecosystem/Ecosystem3';
import Ecosystem4 from '../../assets/icons/ecosystem/Ecosystem4';
import { InView } from 'react-intersection-observer';

export const ecosystemListData: {icon: JSX.Element, title: string, description: string}[] = [
  {
    icon: <Ecosystem1 />,
    description: 'Automate your Dine-in, Take-out, Delivery and Catering services',
    title: 'Food ordering software',
  },
  {
    icon: <Ecosystem2 />,
    description: 'Manage food order, food delivery and inventory.',
    title: 'SmartLiink System',
  },
  {
    icon: <Ecosystem3 />,
    description: 'Run a sustainable national network of catering and food deliveries.',
    title: 'LiinkNet',
  },
  {
    icon: <Ecosystem4 />,
    description: 'Effectively organize event activities for offices and companies with easy food ordering matching attendees\' profile.',
    title: 'LiinkEvent',
  },
];

function Ecosystem() {
  return (
    <EcosystemStyles id="ecosystem">
      <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="container">
        <h2>
          LiiNKED ecosystem
        </h2>

        <ul>
          {ecosystemListData.map((item) => (
            <li key={item.title}>
              <div className="iconContainer">
                {item.icon}
              </div>

              <div className="textContainer">
                <h3 className="textSemiBold22">{item.title}</h3>

                <p className="textBase18">{item.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </InView>
    </EcosystemStyles>
  );
}

export default Ecosystem;
