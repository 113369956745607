import React from 'react';

function Ecosystem3() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="40"
      fill="none"
      viewBox="0 0 39 40"
    >
      <path
        fill="#FF6F00"
        d="M26.904 17.5H12.096a5.094 5.094 0 01-1.424-9.984 4.345 4.345 0 014.634-4.3 4.342 4.342 0 018.388 0 4.345 4.345 0 014.634 4.3 5.092 5.092 0 01-1.424 9.981v.003zM15.012 4.575a2.978 2.978 0 00-2.944 3.388.683.683 0 01-.569.768 3.725 3.725 0 00.598 7.403h14.807A3.725 3.725 0 0027.5 8.73a.683.683 0 01-.568-.768 2.974 2.974 0 00-3.635-3.307.683.683 0 01-.838-.598 2.974 2.974 0 00-5.92 0 .683.683 0 01-.838.598 3.004 3.004 0 00-.69-.081z"
      />
      <path
        fill="#FF6F00"
        d="M7.6 28.104a.683.683 0 01-.684-.684v-5.322a.683.683 0 01.683-.683h11.218v-4.598a.683.683 0 011.366 0v5.281a.683.683 0 01-.683.683H8.282v4.64a.683.683 0 01-.683.683z"
      />
      <path
        fill="#FF6F00"
        d="M31.4 28.103a.683.683 0 01-.682-.683v-4.64H19.5a.683.683 0 110-1.365h11.9a.683.683 0 01.684.683v5.322a.683.683 0 01-.683.683zm-16.884 8.84H.683A.683.683 0 010 36.258V27.42a.683.683 0 01.683-.683h13.831a.683.683 0 01.683.683v8.84a.683.683 0 01-.681.682zM1.368 35.575h12.465v-7.473H1.366l.002 7.473z"
      />
      <path
        fill="#FF6F00"
        d="M7.6 39.788a.683.683 0 01-.684-.683v-2.846a.683.683 0 011.366 0v2.846a.683.683 0 01-.683.683z"
      />
      <path
        fill="#FF6F00"
        d="M10.778 39.788H4.42a.683.683 0 010-1.366h6.357a.683.683 0 110 1.366zm27.539-2.845H24.486a.683.683 0 01-.683-.683v-8.84a.683.683 0 01.683-.683h13.831a.683.683 0 01.683.683v8.84a.683.683 0 01-.683.683zm-13.148-1.366h12.465v-7.473H25.169v7.473z"
      />
      <path
        fill="#FF6F00"
        d="M31.4 39.788a.683.683 0 01-.682-.683v-2.846a.683.683 0 111.366 0v2.846a.683.683 0 01-.683.683z"
      />
      <path
        fill="#FF6F00"
        d="M34.58 39.788h-6.358a.683.683 0 110-1.366h6.357a.683.683 0 010 1.366z"
      />
    </svg>
  );
}

export default Ecosystem3;
