import React from 'react';
import { MainStyles } from './MainStyles';
import Header from '../../components/header/Header';
import Banner from '../../components/banner/Banner';
import Ecosystem from '../../components/ecosystem/Ecosystem';
import Benefits from '../../components/benefits/Benefits';
import Footer from '../../components/footer/Footer';
import BottomBar from '../../components/bottomBar/BottomBar';
import Join from '../../components/join/Join';
import Improving from '../../components/improving/Improving';

function Main() {
  return (
    <MainStyles>
      <Header />
      <Banner />
      <Ecosystem />
      <Benefits />
      <Improving />
      <Join />
      <Footer />
      <BottomBar />
    </MainStyles>
  );
}

export default Main;
