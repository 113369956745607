import React from 'react';
import { Link } from 'react-router-dom';
import { BannerStyles } from './BannerStyles';
import { InView } from 'react-intersection-observer';

function Banner() {
  return (
    <BannerStyles>
      <InView onChange={(inView, entry) => (inView ? entry.target.classList.add('active') : null)} className="container">
        <h1>
          Deploying technologies to help restaurants organize communities, streamline operational and marketing activities.
        </h1>

        <a href="#join">Join LiiNKED</a>
      </InView>
    </BannerStyles>
  );
}

export default Banner;
