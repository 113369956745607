import React from 'react';

function BallRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="222"
      height="302"
      fill="none"
      viewBox="0 0 222 302"
      className="ballRight"
    >
      <circle
        cx="221.981"
        cy="79.981"
        r="219.5"
        stroke="url(#paint0_linear_3_1313)"
        strokeWidth="4"
        opacity="0.2"
        transform="rotate(166.186 221.981 79.98)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_1313"
          x1="203.527"
          x2="391.581"
          y1="-140.316"
          y2="257.079"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A237E" />
          <stop offset="1" stopColor="#1A237E" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BallRight;
